<template>
  <div class="teacher_content">
    <div class="page-top-bg"></div>

    <div class="page-top-bg-2">
      <icon name="bg-dot-circle" class="pc-bg2"></icon>
      <icon name="bg-dot-circle" class="sp-bg2"></icon>
    </div>

    <div class="content-body">
      <div class="title-bar">
        <commonTitle
          title="TEACHERS"
          subTitle="講師一覧"
          class="title"
        ></commonTitle>
        
        <div class="teacher-input">
          <input
            type="text"
            v-model="viewState.searchText"
            @change="search"
          />
          <icon
            name="search-btn"
            class="search-btn"
            @click="search"
          ></icon>
        </div>
      </div>

      <template v-if="viewState.isDisplayed">
        <div class="page-action">
          <div class="page-action-nation">
            <span class="nation-title"
              >Page {{ viewState.nowPage }}/{{ viewState.allPage }}</span
            >
            <div class="page-action-nation-button">
              <p class="prev-button" @click="prev">
                <icon
                  name="swiper-button-prev-btn"
                  class="prev-button-icon"
                />
              </p>
              <p class="next-button" @click="next">
                <icon
                  name="swiper-button-next-btn"
                  class="next-button-icon"
                />
              </p>
            </div>
          </div>
        </div>

        <div class="teacher-list">
          <article
            v-for="(teacher) of viewState.teachers"
            :key="teacher.key"
            class="teacher-list-item"
          >
            <teacherListItem
              :teacher="teacher"
            />
          </article>
        </div>

        <div class="page-action-nation page-action-nation--bottom">
          <span class="nation-title"
            >Page {{ viewState.nowPage }}/{{ viewState.allPage }}</span
          >
          <div class="page-action-nation-button">
            <p class="prev-button" @click="prev">
              <icon name="swiper-button-prev-btn" class="prev-button-icon" />
            </p>
            <p class="next-button" @click="next">
              <icon name="swiper-button-next-btn" class="next-button-icon" />
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import util from "@/utils/util";
import http from "@/utils/http";
import teacherListItem from "./components/TeacherListItem.vue";
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    teacherListItem,
    commonTitle,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const viewState = reactive({
      allTeachers: {},
      teachers: {},
      searchText: "",
      allPage: 1,
      nowPage: 1,
      isDisplayed: false,
    });

    const ITEMS_PER_PAGE = 12;

    const isSp = computed(() => store.state.isSp);
    const isEnterprise = computed(() => store.state.user.isEnterprise);

    window.scrollTo(0, 0);

    // 全件取得
    const fetchInstructors = () => {
      return http
        .get('/api/instructors')
        .then((response) => {
          viewState.allTeachers = response.data;
          
          viewState.teachers = viewState.allTeachers.slice(0, ITEMS_PER_PAGE);
          viewState.allPage = Math.ceil(response.data.length / ITEMS_PER_PAGE);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };

    // 検索
    const search = () => {
      return http
        .get(`/api/instructors?search=${viewState.searchText}`)
        .then((response) => {
          viewState.allTeachers = response.data;
          
          viewState.teachers = viewState.allTeachers.slice(0, ITEMS_PER_PAGE);
          viewState.allPage = Math.ceil(response.data.length / ITEMS_PER_PAGE);
          viewState.nowPage = 1;
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };

    // 前のぺージ
    const prev = () => {
      if (viewState.nowPage > 1) {
        document.getElementById("app").scrollIntoView();
        viewState.nowPage = viewState.nowPage - 1;
        viewState.teachers = viewState.allTeachers.slice(
          viewState.nowPage * ITEMS_PER_PAGE - ITEMS_PER_PAGE,
          viewState.nowPage * ITEMS_PER_PAGE
        );
      }
    };

    // 次のぺージ
    const next = () => {
      if (viewState.nowPage < viewState.allPage) {
        document.getElementById("app").scrollIntoView();
        viewState.nowPage = viewState.nowPage + 1;
        viewState.teachers = viewState.allTeachers.slice(
          viewState.nowPage * ITEMS_PER_PAGE - ITEMS_PER_PAGE,
          viewState.nowPage * ITEMS_PER_PAGE
        );
      }
    };

    onMounted(async () => {
      if (isEnterprise.value) {
        router.push("/");
        return;
      }

      await fetchInstructors();
      viewState.isDisplayed = true;
    });
    watch(
      () => store.state.user.isEnterprise,
      () => {
        if (isEnterprise.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      util,
      viewState,
      isSp,
      isEnterprise,

      // method
      search,
      prev,
      next,
    };
  },
});
</script>

<style lang="scss" scoped>
.teacher_content {
  max-width: 1366px;
  position: relative;
  margin: 0 auto 115px;

  @include mq(sm) {
    margin-bottom: 80px;
  }

  .page-top-bg {
    max-width: 1368px;
    width: 1368px;
    min-height: 50px;
    background-image: url("../../assets/img/top-login-top-bg.png");
    background-size: 100%;
    z-index: 1;
    @include mq(sm) {
      background-image: url("../../assets/img/sp-top-bg.png");
    }
  }

  .page-top-bg-2 {
    position: absolute;
    width: 620px;
    height: 620px;
    left: -310px;
    top: -280px;
    z-index: -1;

    @include mq(sm) {
      left: -141px;
      top: -91px;
    }

    .pc-bg2 {
      min-width: 620px;
      min-height: 620px;
      @include mq(sm) {
        display: none;
      }
    }

    .sp-bg2 {
      display: none;
      @include mq(sm) {
        display: block;
        width: 282px;
        height: 282px;
      }
    }
  }

  .content-body {
    margin: 0 50px;

    @include mq(sm) {
      margin: 0 20px;
    }

    .title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 65px;
      flex-wrap: wrap;
      

      @include mq(sm) {
        flex-direction: column;
      }

      .title {
        margin-right: 40px;

        @include mq(sm) {
          margin-right: auto;
        }
      }

      .teacher-input {
        max-width: 307px;
        flex: 1;
        height: 40px;
        position: relative;
        border-bottom: 1px solid $black;
        border-bottom-right-radius: 23px;

        @include mq(sm) {
          margin: 20px auto 0 auto;
          max-width: 307px;
          width: 100%;
        }

        input {
          display: block;
          height: 40px;
          min-height: 40px;
          border: none;
          padding: 0 40px 0 0;
          font-size: 20px;
          border-radius: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.08em;
          color: $black;

          &:focus {
            outline: none;
          }
        }

        .search-btn {
          position: absolute;
          width: 40px;
          height: 40px;
          right: 0px;
          top: 0px;
          cursor: pointer;
        }
      }
    }

    .page-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 40px;

      @include mq(sm) {
        flex-direction: column;
        align-items: flex-start;
        margin: 30px 30px 0 30px;
      }
    }
    .page-action-nation {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: $black-50;

      @include mq(sm) {
        width: 100%;
        justify-content: space-between;
      }

      &--bottom {
        justify-content: end;
        margin-top: 5px;
      }

      .nation-title {
        margin-right: 20px;
      }
      
      .page-action-nation-button {
        display: flex;
        align-items: center;
        cursor: pointer;

        .prev-button-icon,
        .next-button-icon {
          width: 40px;
          height: 40px;
        }

        .prev-button {
          margin-right: 20px;
        }
      }
    }

    .teacher-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 50px 0 0 65px;

      @include mq(sm) {
        margin: 50px 30px 0 30px;
      }
      
      .teacher-list-item {
        margin-bottom: 60px;
        max-width: calc(25% - 28px);
        min-width: 255px;
        text-align: center;
      }
    }
  }
}
</style>
