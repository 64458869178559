<template>
  <div
    class="teacher-div"
    :style="{
      'background-image': isSp ? `url(${spBgImgSrc})` : '',
      'background-size': 'cover',
    }"
    @mouseover="itemMouseover(this)"
    @mouseleave="itemMouseleave(this)"
    @click="itemClick"
  >
    <img
      :src="teacher?.image_url"
      :alt="teacher?.name"
      class="teacher-image"
    />
    <div class="teacher-name">
      <p :title="teacher?.name" >{{ teacher?.name }}</p>
    </div>
    <div class="teacher-detail">
      <p :title="teacher?.company_name" class="teacher-company-name">{{ teacher?.company_name }}</p>
      <p :title="teacher?.position" class="teacher-position">{{ teacher?.position }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  props: ["teacher"],
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const isSp = computed(() => store.state.isSp);
    const bgImgSrc = computed(() => require(`../../../assets/img/course-hover-bg.png`));
    const spBgImgSrc = computed(() => require(`../../../assets/img/course-sp-bg.png`));

    // method
    const itemMouseover = (e) => {
      if (isSp.value) return
      e.$el.style.background = `url(${bgImgSrc.value})`
      e.$el.style.backgroundSize = '100%'
    };
    const itemMouseleave = (e) => {
      if (isSp.value) return
      e.$el.style.background = ``
    };
    const itemClick = () => {
      console.log("click")
      router.push(`/teacher-detail/${props.teacher.code}`);
    }

    return {
      isSp,
      spBgImgSrc,

      // method
      itemMouseover,
      itemMouseleave,
      itemClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.teacher-div {
  width: 227px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .teacher-image {
    width: 227px;
    height: 227px;
    object-fit: cover;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
  }

  .teacher-name {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 20px 20px 0 20px;
  }

  .teacher-detail {
    margin: 20px 20px 0 20px;
    padding-bottom: 20px;
    font-size: 0.875rem;
    color: #878788;

    .teacher-position {
      margin-top: 0.5em;
    }
  }
}
</style>
